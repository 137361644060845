document.addEventListener("DOMContentLoaded", () => {
	const showMoreButtons = document.querySelectorAll(".show-more");
	if (showMoreButtons.length > 0) {
		showMoreButtons.forEach(button => {
			button.addEventListener("click", () => {
				const wrapper = button.parentElement.querySelector(".show-more-wrapper");
				wrapper.classList.toggle("visible");

				if (wrapper.classList.contains("visible")) {
					button.textContent = "less-";
				} else {
					button.textContent = "more+";
				}
			});
		});
	}
});