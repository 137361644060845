document.addEventListener("DOMContentLoaded", () => {
	const videoContainers = document.querySelectorAll(".video-container");

	videoContainers.forEach(videoContainer => {
		const playBtn = videoContainer.querySelector(".play-icon");
		const videoElement = videoContainer.querySelector("video");
		const youtubePlayer = videoContainer.querySelector(".youtube-player");
		const placeholder = videoContainer.querySelector(".youtube-placeholder");

		if (playBtn) {
			playBtn.addEventListener("click", () => {
				// Handle local video playback
				if (videoElement) {
					videoContainer.classList.add("hide-overlay");
					videoElement.play();
					videoElement.setAttribute("controls", "controls");
				}

				// Handle YouTube video lazy loading
				if (youtubePlayer) {
					const videoId = youtubePlayer.dataset.videoId;
					if (videoId) {
						youtubePlayer.src = `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=1&modestbranding=1&rel=0`;
					}
					videoContainer.classList.add("hide-overlay");

					// Hide the placeholder if present
					if (placeholder) {
						placeholder.style.display = "none";
					}
					youtubePlayer.style.pointerEvents = "auto";
				}
			});
		}
	});
});
