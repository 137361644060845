document.addEventListener("DOMContentLoaded", () => {
	const mapContainers = document.querySelectorAll(".map-container");

	if (mapContainers.length > 0) {
		// Load the Mapbox script dynamically
		const mapboxScript = document.createElement("script");
		mapboxScript.src = "https://api.mapbox.com/mapbox-gl-js/v3.8.0/mapbox-gl.js";
		mapboxScript.async = true;
		mapboxScript.onload = () => {
			initializeMaps(mapContainers);
		};
		document.body.appendChild(mapboxScript);

		// Load the Mapbox CSS dynamically
		const mapboxCSS = document.createElement("link");
		mapboxCSS.rel = "stylesheet";
		mapboxCSS.href = "https://api.mapbox.com/mapbox-gl-js/v3.8.0/mapbox-gl.css";
		document.head.appendChild(mapboxCSS);
	}
});

function initializeMaps(mapContainers) {
	mapboxgl.accessToken = "pk.eyJ1IjoiaGFtYmx5LWZyZWVtYW4iLCJhIjoiY2tnaDNwZnk3MTZsMzJzbmFxZTJ5N2J0dyJ9.frgogyee2E-n17dwO4VXsg";

	const desktopZoom = 3.5;
	const midZoom = 3;
	const fullWidthZoom = 4.5;
	const mobileZoom = 2.5;

	const usaCenter = [-98.583, 39.833];

	mapContainers.forEach((mapContainer, index) => {
		// Parse data-mapbox-data attribute
		let mapboxData;
		try {
			mapboxData = JSON.parse(mapContainer.getAttribute("data-mapbox-data"));
		} catch (e) {
			console.error(`Invalid JSON in data-mapbox-data for map container #${index + 1}:`, e);
			mapboxData = null;
		}

		// Validate mapboxData
		const hasValidData = mapboxData &&
			Array.isArray(mapboxData.locations) &&
			typeof mapboxData.markerImage === "string";

		// Filter out invalid locations where lat and lng are both 0
		const filteredLocations = hasValidData
			? mapboxData.locations.filter(location => !(location.lat === 0 && location.lng === 0))
			: [];

		// Determine initial zoom and minimum zoom based on window width and classes
		let initialZoom = desktopZoom;
		let minZoom = 2.8;

		if (window.innerWidth < 600) {
			initialZoom = mobileZoom;
		} else if (window.innerWidth < 800) {
			initialZoom = midZoom;
		}

		if (mapContainer.classList.contains("full-width")) {
			initialZoom = fullWidthZoom;
			minZoom = 4;
		}

		let mapCenter = usaCenter.slice();
		let mapZoom = initialZoom;
		let mapBounds = null;

		if (filteredLocations.length > 0) {
			if (filteredLocations.length === 1) {
				mapCenter = [filteredLocations[0].lng, filteredLocations[0].lat];
				mapZoom = initialZoom;
			} else {
				mapBounds = new mapboxgl.LngLatBounds();
				filteredLocations.forEach(location => {
					mapBounds.extend([location.lng, location.lat]);
				});
			}
		} else {
			mapZoom = initialZoom;
		}

		const map = new mapboxgl.Map({
			container: mapContainer,
			style: "mapbox://styles/hambly-freeman/cm3380y7g001z01nwayzmew0b",
			center: mapCenter,
			zoom: mapZoom,
			minZoom: minZoom,
			scrollZoom: false
		});

		mapContainer.style.visibility = "hidden";

		map.on("load", () => {
			if (hasValidData && filteredLocations.length > 0) {
				map.loadImage(mapboxData.markerImage, (error, image) => {
					if (error) {
						console.error("Error loading marker image:", error);
						mapContainer.style.visibility = "visible";
						return;
					}

					const imageName = `custom-marker-${index}`;
					if (!map.hasImage(imageName)) {
						map.addImage(imageName, image);
					}

					const geojson = {
						type: "FeatureCollection",
						features: filteredLocations.map(location => ({
							type: "Feature",
							geometry: {
								type: "Point",
								coordinates: [location.lng, location.lat],
							},
							properties: {
								title: location.title,
								permalink: location.permalink,
							},
						})),
					};

					const sourceId = `locations-${index}`;
					if (!map.getSource(sourceId)) {
						map.addSource(sourceId, {
							type: "geojson",
							data: geojson,
							cluster: true,
							clusterMaxZoom: 10,
							clusterRadius: 50,
						});
					}

					// Add clustered and unclustered layers
					addClusteredLayers(map, sourceId, index);
				});
			} else {
				if (!hasValidData) {
					console.error(`Marker image URL not found or data is invalid for map container #${index + 1}.`);
				}
			}

			map.scrollZoom.disable();
			map.on("click", () => {
				if (!map.scrollZoom.isEnabled()) {
					map.scrollZoom.enable();
				}
			});
			map.on("mouseout", () => {
				if (map.scrollZoom.isEnabled()) {
					map.scrollZoom.disable();
				}
			});

			if (mapBounds) {
				map.fitBounds(mapBounds, {
					padding: 50,
					maxZoom: 14,
					duration: 0,
				});
			}

			if (filteredLocations.length === 1) {
				map.setCenter([filteredLocations[0].lng, filteredLocations[0].lat]);
				map.setZoom(initialZoom);
			}

			mapContainer.style.visibility = "visible";
		});
	});
}

function addClusteredLayers(map, sourceId, index) {
	const clustersLayerId = `clusters-${index}`;
	const clusterCountLayerId = `cluster-count-${index}`;
	const unclusteredLayerId = `unclustered-point-${index}`;

	// Clustered layers
	if (!map.getLayer(clustersLayerId)) {
		map.addLayer({
			id: clustersLayerId,
			type: "circle",
			source: sourceId,
			filter: ["has", "point_count"],
			paint: {
				"circle-color": "#e0cff8",
				"circle-radius": [
					"step",
					["get", "point_count"],
					20,
					100,
					30,
					750,
					40
				],
			},
		});
	}

	if (!map.getLayer(clusterCountLayerId)) {
		map.addLayer({
			id: clusterCountLayerId,
			type: "symbol",
			source: sourceId,
			filter: ["has", "point_count"],
			layout: {
				"text-field": "{point_count_abbreviated}",
				"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
				"text-size": 12,
			},
			paint: {
				"text-color": "#ffffff",
			},
		});
	}

	// Unclustered points
	if (!map.getLayer(unclusteredLayerId)) {
		map.addLayer({
			id: unclusteredLayerId,
			type: "symbol",
			source: sourceId,
			filter: ["!", ["has", "point_count"]],
			layout: {
				"icon-image": `custom-marker-${index}`,
				"icon-size": 0.5,
				"icon-allow-overlap": true,
				"icon-anchor": "bottom",
			},
		});
	}
}
