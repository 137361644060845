import gsap from "gsap";

document.addEventListener("DOMContentLoaded", () => {
	const faqs = document.querySelectorAll("article.faq");

	const toggleAnswer = (answer, isOpen) => {
		gsap.to(answer, {
			height: isOpen ? "auto" : 0,
			duration: 0.4,
			ease: 'power2.out',
		});
	};

	if (faqs.length > 0) {
		faqs.forEach(faq => {
			const question = faq.querySelector(".question");
			const answer = faq.querySelector(".answer");

			question.addEventListener("click", () => {
				// Close all other FAQs
				faqs.forEach(otherFaq => {
					const otherQuestion = otherFaq.querySelector(".question");
					const otherAnswer = otherFaq.querySelector(".answer");

					if (otherQuestion !== question && otherQuestion.classList.contains("open")) {
						otherQuestion.classList.remove("open");
						toggleAnswer(otherAnswer, false);
					}
				});

				// Toggle the clicked FAQ
				const isOpen = question.classList.toggle("open");
				toggleAnswer(answer, isOpen);
			});
		});
	}
});
