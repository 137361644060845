document.addEventListener("DOMContentLoaded", () => {
	if (document.querySelector(".hero-form") && hubspotFormCode.formCode) {
		function loadHubSpotForm() {
			const hsScript = document.createElement("script");
			hsScript.async = true; // Load script asynchronously
			hsScript.src = "//js.hsforms.net/forms/embed/v2.js";
			hsScript.onload = function () {
				try {
					// Dynamically execute the HubSpot form configuration
					eval(hubspotFormCode.formCode);
				} catch (e) {
					console.error("Error executing HubSpot form configuration:", e);
				}
			};
			document.body.appendChild(hsScript);
		}

		if ("requestIdleCallback" in window) {
			requestIdleCallback(loadHubSpotForm);
		} else {
			setTimeout(loadHubSpotForm, 200);
		}
	}
});
