import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

document.addEventListener("DOMContentLoaded", () => {
	const testimonialSwipers = document.querySelectorAll(".testimonials-swiper");

	if (testimonialSwipers.length > 0) {
		testimonialSwipers.forEach(swiper => {
			new Swiper(swiper, {
				modules: [Pagination],
				slidesPerView: 1,
				spaceBetween: 30,
				speed: 400,
				loop: true,
				grabCursor: true,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
			});
		});
	}


	const featuredPostsSwipers = document.querySelectorAll(".featured-posts-swiper");

	if (featuredPostsSwipers.length > 0) {
		featuredPostsSwipers.forEach(swiper => {
			new Swiper(swiper, {
				slidesPerView: "auto",
				spaceBetween: 20,
				grabCursor: true,
				modules: [Navigation],
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				breakpoints: {
					1200: {
						slidesPerView: 4,
					},
					1440: {
						slidesPerView: 4,
					}
				}
			})
		})
	}
});