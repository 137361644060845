// document.addEventListener("DOMContentLoaded", () => {
// 	const heroForm = document.querySelector(".hero-form");
// 	if (heroForm) {
// 		const selectInput = heroForm.querySelector("select");
//
// 		if (selectInput.value === "") { // Assuming the default placeholder value is empty
// 			selectInput.classList.add("placeholder-active");
// 		} else {
// 			selectInput.classList.remove("placeholder-active");
// 		}
//
// 		selectInput.addEventListener("change", function () {
// 			if (selectInput.value === "") { // Assuming the placeholder value is empty
// 				selectInput.classList.add("placeholder-active");
// 			} else {
// 				selectInput.classList.remove("placeholder-active");
// 			}
// 		});
// 	}
// });