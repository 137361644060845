// script.js
import gsap from "gsap";

document.addEventListener("DOMContentLoaded", () => {
	const header = document.querySelector("#site-header");
	let lastScrollY = 0;

	function handleScroll() {
		const currentScrollY = window.scrollY;

		if (currentScrollY > header.offsetHeight) {
			if (!header.classList.contains("fixed")) {
				header.classList.add("fixed");
				setTimeout(() => header.classList.add("scrolled"), 0); // Delay to ensure no transition glitch
			}

			if (currentScrollY < lastScrollY) {
				// Scrolling up
				header.classList.remove("hidden");
			} else {
				// Scrolling down
				header.classList.add("hidden");
			}
		} else if ((currentScrollY <= 30 && window.innerWidth >= 1200) || (currentScrollY === 0 && window.innerWidth < 1200 )) {
			header.classList.remove("fixed", "hidden", "scrolled");
		}

		lastScrollY = currentScrollY;
	}

	window.addEventListener("scroll", handleScroll);
});
