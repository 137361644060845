document.addEventListener('DOMContentLoaded', function () {
	const loadMoreButtons = document.querySelectorAll('.load-more');

	loadMoreButtons.forEach(function(button) {
		button.addEventListener('click', function (e) {
			e.preventDefault();

			const currentPage = parseInt(button.getAttribute('data-page'));
			const maxPage = parseInt(button.getAttribute('data-max'));
			const mediaType = button.getAttribute('data-media-type');

			// Disable the button to prevent multiple clicks
			button.disabled = true;
			button.textContent = 'Loading...';

			// Prepare the data to send
			const data = new FormData();
			data.append('action', 'load_more_media');
			data.append('nonce', load_more_params.nonce);
			data.append('page', currentPage + 1);
			data.append('media_type', mediaType);

			// Send the AJAX request using Fetch API
			fetch(load_more_params.ajax_url, {
				method: 'POST',
				body: data,
				credentials: 'same-origin',
			})
				.then(response => response.json())
				.then(data => {
					if (data.success) {
						const html = data.data.html.trim();

						if (html.length) {
							// Find the closest links-list based on media type
							const linksList = button.closest('.links-container').querySelector('.links-list.' + mediaType);
							linksList.insertAdjacentHTML('beforeend', html);

							// Update the current page
							button.setAttribute('data-page', currentPage + 1);

							if (currentPage + 1 >= maxPage) {
								// No more pages left, remove the button
								button.remove();
							} else {
								// Re-enable the button
								button.disabled = false;
								button.textContent = 'Read More +';
							}
						} else {
							// No more posts, remove the button
							button.remove();
						}
					} else {
						// Handle failure
						button.disabled = false;
						button.textContent = 'Read More +';
						console.error('No more posts to load.');
					}
				})
				.catch(error => {
					// Handle error
					button.disabled = false;
					button.textContent = 'Read More +';
					console.error('An error occurred while loading more posts:', error);
				});
		});
	});
});
