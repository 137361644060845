import gsap from "gsap";

document.addEventListener("DOMContentLoaded", () => {
	const menuItemsWithSubMenu = document.querySelectorAll(".menu-item-has-children");
	const menuToggle = document.querySelector(".menu-toggle");
	const siteMenu = document.querySelector(".main-menu");
	const closeBtn = document.querySelector(".menu-close-btn");
	const siteHeader = document.querySelector("#site-header");
	const body = document.body; // Reference to the body element

	let isDesktop = window.innerWidth >= 1200;

	// Function to handle desktop submenu alignment
	const handleDesktopSubmenu = (item) => {
		const submenu = item.querySelector(".sub-menu");
		if (submenu) {
			// Define adjustSubmenu and attach it to the item
			const adjustSubmenu = () => {
				item.classList.remove("align-left", "align-right");

				const submenuRect = submenu.getBoundingClientRect();
				const viewportWidth = window.innerWidth;

				// Check if submenu overflows to the right
				if (submenuRect.right > (viewportWidth - 20)) {
					item.classList.add("align-right");
				}
				// Check if submenu overflows to the left
				else if (submenuRect.left < 0) {
					item.classList.add("align-left");
				}
			};

			// Attach adjustSubmenu to the item for later reference
			item.adjustSubmenu = adjustSubmenu;

			item.addEventListener("mouseenter", adjustSubmenu);
			item.addEventListener("mouseleave", () => {
				item.classList.remove("align-left", "align-right");
			});
		}
	};

	// Function to handle mobile submenu toggle
	const toggleMobileSubmenu = (e) => {
		e.stopPropagation(); // Prevent the event from bubbling up

		const item = e.currentTarget;
		const link = item.querySelector("a");
		const submenu = item.querySelector(':scope > .sub-menu'); // Scope to immediate child

		if (e.target === link && !isDesktop) {
			e.preventDefault();

			item.classList.toggle("open");

			gsap.to(submenu, {
				height: item.classList.contains("open") ? "auto" : 0,
				duration: 0.3,
				ease: "power2.out",
			});
		}
	};

	// Function to initialize menu based on current window width
	const initializeMenu = () => {
		menuItemsWithSubMenu.forEach(item => {
			const submenu = item.querySelector(".menu > li > .sub-menu");

			if (submenu) {
				// Cleanup previous event listeners
				if (isDesktop && item.adjustSubmenu) {
					item.removeEventListener("mouseenter", item.adjustSubmenu);
					item.removeEventListener("mouseleave", () => {
						item.classList.remove("align-left", "align-right");
					});
					delete item.adjustSubmenu;
				}

				if (!isDesktop && item.toggleMobileSubmenu) {
					item.removeEventListener("click", item.toggleMobileSubmenu);
					delete item.toggleMobileSubmenu;
				}

				// Reset submenu styles and classes
				if (!isDesktop) {
					gsap.set(submenu, { height: 0 });
					item.classList.remove("open", "align-left", "align-right");
				} else {
					item.classList.remove("open", "align-left", "align-right");
					// **Clear any inline styles set by GSAP**
					submenu.style.height = ""; // Removes the inline height style
				}

				if (isDesktop) {
					// Desktop behavior
					handleDesktopSubmenu(item);
				} else {
					// Mobile behavior
					const boundToggleMobileSubmenu = toggleMobileSubmenu.bind(item);
					item.toggleMobileSubmenu = boundToggleMobileSubmenu;
					item.addEventListener("click", boundToggleMobileSubmenu);
				}
			}
		});

		isDesktop = window.innerWidth >= 1200;
	};

	// Initial menu setup
	initializeMenu();

	// Handle window resize without debounce for menu functionality
	window.addEventListener("resize", () => {
		const wasDesktop = isDesktop;

		// **Start of resize: disable transitions**
		body.classList.add("disable-transitions");

		// Initialize menu
		initializeMenu();

		// Optionally, handle state transitions
		if (wasDesktop && !isDesktop) {
			siteMenu.classList.remove("open");
			siteHeader.classList.remove("nav-open");
			menuToggle.classList.remove("open");
		}

		if (!wasDesktop && isDesktop) {
			// Ensure all submenus are correctly displayed in desktop view
			menuItemsWithSubMenu.forEach(item => {
				const submenu = item.querySelector(".sub-menu");
				if (submenu) {
					gsap.set(submenu, { height: "auto" }); // Ensure desktop submenus are visible
				}
			});
		}

		// **End of resize: re-enable transitions after a short delay**
		// Use a timeout to remove the class after resizing has likely finished
		clearTimeout(body.resizeTimeout);
		body.resizeTimeout = setTimeout(() => {
			body.classList.remove("disable-transitions");
		}, 300); // Adjust the timeout duration as needed
	});

	// Mobile Nav Toggle
	menuToggle.addEventListener("click", () => {
		siteMenu.classList.toggle("open");
		siteHeader.classList.toggle("nav-open");
		menuToggle.classList.toggle("open");
		body.classList.toggle("nav-open");
	});
});
